h1 {
  font-size: 24px;
}

h3 {
  font-size: 34px;
  font-weight: 600;
}

input {
  border-radius: 5px;
  border-color: #e8e8e8;
  outline: none;
}

.section-image img {
  max-width: 608px;
  box-shadow: 0px 8px 19px 4px rgb(0 0 0 / 21%);
  border-radius: 25px;
}

.btn {
  padding: 10px 24px;
  border-radius: 25px;
  font-weight: 600;
  background-color: #fff;
}

.btn-secondary {
  background-color: #000;
  color: #fff;
}

.navbar {
  background-color: #00226d;
  padding: 19px 53px;
}

.main-section {
  background: linear-gradient(180deg, #d0f4ff 0%, #ffffff 100%);
}

.wiggle-icon {
  transform: translate(-50%);
  top: 38px;
}

.section-three,
.section-five {
  background-color: #f7f7f7;
}

.footer-section {
  background-color: #00226d;
}

.contact-form-container {
  background-color: rgba(0, 0, 0, 0.42);
  width: calc(100vw - 16px);
  height: 100%;
  position: absolute;
  top: 10%;
  width: 100%;
}

.inner-form-container {
  max-width: 650px;
  width: 95%;
  top: 5%;
  transform: translate(-50%);
  border-radius: 8px;
}

@media screen and (max-width: 1199px) {
  h3 {
    font-size: 30px;
  }

  .navbar {
    padding: 19px 24px;
  }

  .section-image img {
    max-width: 100%;
  }

  .wiggle-icon {
    top: 33px;
  }
}

@media screen and (max-width: 400px) {

  section,
  .main-section {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .navbar {
    padding: 19px 12px;
  }

  .navbar .btn {
    font-size: 12px;
  }
}