.input {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s; 
  }
  
  .input:focus {
    border-color: #000000; 
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6); 
  }

  .textBox {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s; 
    resize: none;
    max-height: 100px;
  }

  .textBox:focus {
    border-color: #000000; 
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6); 
  }