@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
}